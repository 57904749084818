import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Power Up Your Command Line III",
  "date": "2019-01-07T18:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/even-more-tools",
  "category": "Software",
  "tags": ["command line", "productivity", "tips"],
  "description": "This is part three of the Power Up Your Command Line series. In this article I’ll showcase five utilities that will make common problems a little easier to solve using the command line."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is part three of the `}<em parentName="p">{`Power Up Your Command Line`}</em>{` series. In this article, I’ll showcase five utilities that will make common problems a little easier to solve using the command line.`}</p>
    <h2><inlineCode parentName="h2">{`tig`}</inlineCode>{`, for interactively browsing your git repo`}</h2>
    <p><inlineCode parentName="p">{`tig`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/jonas/tig"
      }}>{`GitHub`}</a>{`) is an excellent way to browse your Git repository using an interactive interface, without leaving the command line.`}</p>
    <p><img parentName="p" {...{
        "src": "/cb475140e22bbb7f227d7e03f9050556/tig.gif",
        "alt": "tig"
      }}></img></p>
    <p><inlineCode parentName="p">{`tig`}</inlineCode>{` is simple and intuitive to use, and has different views for things like your stash, staging area (which it lets you quickly alter), logs, and so on.`}</p>
    <p>{`Thanks for Renato Suero (`}<a parentName="p" {...{
        "href": "https://twitter.com/renatosuero"
      }}>{`@renatosuero`}</a>{`) for introducing me to `}<inlineCode parentName="p">{`tig`}</inlineCode>{`  on DEV.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`tig`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS with (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install tig`}</inlineCode></li>
    </ul>
    <h2>{`PathPicker (`}<inlineCode parentName="h2">{`fpp`}</inlineCode>{`), for quickly selecting files`}</h2>
    <p>{`PathPicker (`}<a parentName="p" {...{
        "href": "https://github.com/facebook/PathPicker"
      }}>{`GitHub`}</a>{`) is a library by Facebook for quickly selecting files on the command line. The animated example below is taken from the PathPicker documentation.`}</p>
    <p><img parentName="p" {...{
        "src": "/217fdcd6bcffaa605df85f31e8d84d79/fpp.gif",
        "alt": "fpp"
      }}></img></p>
    <p>{`From the `}<a parentName="p" {...{
        "href": "http://facebook.github.io/PathPicker/"
      }}>{`PathPicker website`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`PathPicker accepts a wide range of input -- output from git commands, grep results, searches -- pretty much anything. After parsing the input, PathPicker presents you with a nice UI to select which files you're interested in. After that, you can open them in your favorite editor or execute arbitrary commands.`}</p>
    </blockquote>
    <p>{`Thanks to Nikolay Dubina (`}<a parentName="p" {...{
        "href": "https://twitter.com/nikolayid"
      }}>{`@nikolayid`}</a>{`) for suggesting this utility.`}</p>
    <h3>{`Installing PathPicker`}</h3>
    <ul>
      <li parentName="ul">{`On macOS with (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install fpp`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`tldr`}</inlineCode>{`, for practical examples for how to use CLI tools`}</h2>
    <p><a parentName="p" {...{
        "href": "https://tldr.sh"
      }}><inlineCode parentName="a">{`tldr`}</inlineCode></a>{` (`}<a parentName="p" {...{
        "href": "https://github.com/tldr-pages/tldr/"
      }}>{`GitHub`}</a>{`) lets you quickly access practical examples (simplified, “tl;dr” versions of man pages) of how to use command line tools.`}</p>
    <p><img parentName="p" {...{
        "src": "/2662bd95c1c70e53892d857a3ea990ce/tldr.gif",
        "alt": "tldr"
      }}></img></p>
    <p>{`The examples are maintained by the community in the `}<inlineCode parentName="p">{`tldr`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://github.com/tldr-pages/tldr"
      }}>{`GitHub repository`}</a>{`.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`tldr`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`With npm (recommended): `}<inlineCode parentName="li">{`npm install -g tldr`}</inlineCode></li>
      <li parentName="ul">{`On macOS with (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install tldr`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`gron`}</inlineCode>{`, for exploring JSON`}</h2>
    <p><inlineCode parentName="p">{`gron`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/tomnomnom/gron"
      }}>{`GitHub`}</a>{`) transforms JSON text into discrete assignments, to make it easier to find what you need. I particularly like using it for interactively exploring APIs in combination with `}<inlineCode parentName="p">{`fzf`}</inlineCode>{` (mentioned earlier in this series):`}</p>
    <p><img parentName="p" {...{
        "src": "/40ffb9ae640c6b9e89fd95de3ad88b0e/gron-with-fzf.gif",
        "alt": "gron-with-fzf"
      }}></img></p>
    <p>{`You can also use `}<inlineCode parentName="p">{`gron`}</inlineCode>{` to help you transform JSON objects (`}<a parentName="p" {...{
        "href": "https://github.com/tomnomnom/gron/blob/master/ADVANCED.mkd"
      }}>{`examples`}</a>{`). This isn’t the main use-case of `}<inlineCode parentName="p">{`gron`}</inlineCode>{` though, and you’d probably be better served using a purpose built tool like `}<a parentName="p" {...{
        "href": "https://stedolan.github.io/jq/"
      }}><inlineCode parentName="a">{`jq`}</inlineCode></a>{` for such things.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`gron`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS with (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install gron`}</inlineCode></li>
    </ul>
    <h2>{`thefuck, for quickly correcting command line typos`}</h2>
    <p>{`If you mistype a command, type `}<inlineCode parentName="p">{`fuck`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/nvbn/thefuck"
      }}>{`GitHub`}</a>{`) and you’ll be given a list of corrections to choose from.`}</p>
    <p><img parentName="p" {...{
        "src": "/7ed922a3cb4d321b0f872818ee3c5162/thefuck.gif",
        "alt": "thefuck"
      }}></img></p>
    <p>{`The name of the command is unfortunately slightly NSFW, so you may wish to alias it to something else.`}</p>
    <h3>{`Installing thefuck`}</h3>
    <ul>
      <li parentName="ul">{`On macOS with (`}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`): `}<inlineCode parentName="li">{`brew install thefuck`}</inlineCode></li>
    </ul>
    <h2>{`Bonus: explainshell, for explaining shell commands`}</h2>
    <p>{`If you’re in the situation where you already have a complex shell command and want to understand what it does without browsing `}<inlineCode parentName="p">{`man`}</inlineCode>{` or `}<inlineCode parentName="p">{`tldr`}</inlineCode>{` pages, you can use `}<a parentName="p" {...{
        "href": "https://explainshell.com"
      }}>{`explainshell`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0edc1706c18af98816645e6b2db89607/15171/explain_shell.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.74233128834356%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABPUlEQVQoz02R2dLaMAxGc1/I5n0JARLHBP6tff93Ox0b6PTijD5/0kiy3RzalmPXcWxbBq3olUJrjXMObSxxOqGNqV4MocbiOx8JcaLtBn4djhyObaWRSiGUfKIlUkmUVoxC0A0jgxAc2o4yWIw9Q9/T933NCymR/yGkplHOYqdIPJ8x3tMJQS/fyBqL9/aHFzUvxn+1gxzr5o12lsu6EOcZbS2mXLXy1MraqgvKmlqjXrw9UZ9BY6yjcc4znc58//zh6/s3Ke8s60bKN9YtV73lncuSWFJmTZnb/qixnLVxdIPA2vKugWaaTvgQ2fYHeX8wXxbO15XrurFut+qlV0PjI8p6tAtoW/CMUjPI5yf6MNFYH4nT/NwoZfJ+5/7xWbcqOt/uddP98VH1dU1c6sDEmrbaxDhPCJEwzfwFgJG2f1hu8lkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "explain_shell",
            "title": "explain_shell",
            "src": "/static/0edc1706c18af98816645e6b2db89607/a6d36/explain_shell.png",
            "srcSet": ["/static/0edc1706c18af98816645e6b2db89607/222b7/explain_shell.png 163w", "/static/0edc1706c18af98816645e6b2db89607/ff46a/explain_shell.png 325w", "/static/0edc1706c18af98816645e6b2db89607/a6d36/explain_shell.png 650w", "/static/0edc1706c18af98816645e6b2db89607/e548f/explain_shell.png 975w", "/static/0edc1706c18af98816645e6b2db89607/3c492/explain_shell.png 1300w", "/static/0edc1706c18af98816645e6b2db89607/15171/explain_shell.png 2230w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Conclusion`}</h2>
    <p>{`Hopefully you found something on this page that interests you! If you’re interested in more content like this, follow me on `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{` and on `}<a parentName="p" {...{
        "href": "https://dev.to/_darrenburns"
      }}>{`DEV`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      